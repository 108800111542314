<template>
  <v-container>
    <div v-show="isVisibleProp"></div>
    <v-row>
      <v-col cols="3">
        <v-text-field
          :autofocus="true"
          label="Bank Name"
          v-model="editedItem.bankName"
          :rules="reqdSelectValidation"
        >
        </v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          label="Account Number"
          v-model="editedItem.accountNumber"
        >
        </v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          label="Routing Number"
          v-model="editedItem.routingNumber"
        >
        </v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          label="Alt Key"
          v-model="editedItem.altKeyBase"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-combobox
          label="Group Code"
          v-model="editedItem.groupCode"
          :items="bankGroupCodesUsingInitials"
          item-text="groupCodes"
          item-value="_id"
          @change="getCollateralInfo"
        >
        </v-combobox>
      </v-col>
      <v-col cols="3">
        <v-text-field
          label="Bank Code"
          v-model="editedItem.bankCode"
        >
        </v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          label="Line"
          v-model.number="editedItem.line"
          type="number"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2">
        <v-checkbox
          label="Inv Acct"
          v-model="editedItem.isInvAcct"
        >
        </v-checkbox>
      </v-col>
      <v-col cols="2">
        <v-checkbox
          label="Recon Acct"
          v-model="editedItem.isReconAcct"
        >
        </v-checkbox>
      </v-col>
      <v-col cols="2">
        <v-checkbox
          label="Subledger"
          v-model="editedItem.isSubledger"
        >
        </v-checkbox>
      </v-col>
    </v-row>
    <v-row v-if="isBankCollateral">
      <v-col>
        <sharedBankCollateralDetails
          :baseCrudKey="baseCrudKey"
          v-model="editedItem.bankCollateralRecord"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  mapGetters,
  mapMutations,
} from 'vuex';
import { checkStringEmptiness } from '../../../util/shared/vue-global';
import sharedBankCollateralDetails from './sharedBankCollateralDetails.vue';

export default {
  name: 'BankEditedItem',
  components: {
    sharedBankCollateralDetails,
  },
  data: () => ({
    collateralBankCrudKey: 'collateralBank',
    isBankCollateral: false,
  }),
  props: {
    baseCrudKey: {
      type: String,
      default: 'bank-dialog',
    },
  },
  created() {
  },
  computed: {
    ...mapGetters('base/crud', [
      'criteria',
      'item',
      'items',
      'isVisible',
    ]),
    editedItem: {
      get() {
        return this.item(this.baseCrudKey);
      },
      set(value) {
        this.setItem([this.baseCrudKey, value]);
      },
    },
    changes() {
      return this.editedItem.changes || [];
    },
    reqdSelectValidation() {
      return [
        (value) => !!value || 'Required',
      ];
    },
    isVisibleProp() {
      const isVisible = this.isVisible(this.baseCrudKey);
      if (isVisible) {
        this.getCollateralInfo();
      }
      return isVisible;
    },
    bankGroupCodesUsingInitials() {
      const collateralBankObjs = this.items(this.collateralBankCrudKey) || [];
      const updatedObj = collateralBankObjs.map((c) => c.bankIntials).filter(Boolean);
      return updatedObj;
    },
  },
  methods: {
    ...mapMutations('base/crud', [
      'setItem',
    ]),
    getCollateralInfo() {
      const collateralList = this.items(this.collateralBankCrudKey);
      this.editedItem.bankCollateralRecord = {};
      this.isBankCollateral = false;
      if (!checkStringEmptiness(this.editedItem.groupCode)) {
        for (let i = 0; i < collateralList.length; i += 1) {
          if (this.editedItem.groupCode === collateralList[i].bankIntials) {
            this.editedItem.bankCollateralRecord = collateralList[i];
            this.isBankCollateral = true;
          }
        }
      }
    },
  },
};
</script>
