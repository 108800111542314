<template>
  <v-container>
    <CRUDList
      :canAdd="true"
      :canEdit="true"
      :canSave="true"
      :showSelect="true"
      :headers="headers"
      :mergeCriteria="false"
      :defaultSortBy="sortBy"
      :baseCrudKey="baseCrudKey"
      :selectedItems="selectedItems"
      :createNewItem="newItemDialog"
      toolbarTitle="Banks"
      @loadCRUDList="handleLoadCRUDList"
      @upsertItem="upsrtBank"
      @emitSelectedItem="emitSelectedItem"
    >
      <template v-slot:[`additionalToolsAtRight`]="{}">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              id="sharedBankListRenumberLinesBtn"
              class="shared-bank-list-renumber-lines-btn"
              color="primary"
              fab icon
              :disabled="(selectedItems.length) !== 1"
              @click="renumberLines"
            >
              <v-icon large>
                {{ icons.mdiCounter }}
              </v-icon>
            </v-btn>
          </template>
          <span>Renumber Lines</span>
        </v-tooltip>
      </template>
      <template v-slot:[`editedItem`]="{}">
        <BankEditedItem
          :baseCrudKey="baseCrudKey"
        />
      </template>
    </CRUDList>
  </v-container>
</template>

<script>
import {
  mapGetters,
  mapActions,
  mapMutations,
  mapState,
} from 'vuex';
import { mdiCounter } from '@mdi/js';
import CRUDList from '../../common/base/crud/CRUDList.vue';
import BankEditedItem from './BankEditedItem.vue';

const getHeaders = () => {
  const headers = [
    { text: 'Bank Name', value: 'bankName' },
    { text: 'Account Number', value: 'accountNumber' },
    { text: 'Routing Number', value: 'routingNumber' },
    { text: 'Alt Key', value: 'altKeyBase' },
    // for now added line in the headers so that can easily check if lines are renumbered or not
    { text: 'Line', value: 'line' },
    { text: 'Group Code', value: 'groupCode' },
    { text: 'Bank Code', value: 'bankCode' },
    // { text: 'Bank Address', value: 'bankAddress' },
  ];
  return headers;
};

export default {
  name: 'BankList',
  components: {
    CRUDList,
    BankEditedItem,
  },
  data: () => ({
    headers: [],
    baseCrudKey: 'sharedBank',
    collateralBankCrudKey: 'collateralBank',
    sortBy: [
      {
        column: 'bankName',
        direction: 'asc',
      },
    ],
    icons: {
      mdiCounter,
    },
  }),
  created() {
    this.headers = getHeaders(this.canDelete);
    this.loadCollateralList();
  },
  computed: {
    ...mapGetters('base/crud', [
      'criteria',
      'index',
      'items',
    ]),
    ...mapState({
      storedSelectedItems: (state) => state.shared.bank.selectedItems,
    }),
    selectedItems: {
      get() {
        return this.storedSelectedItems;
      },
      set(value) {
        this.setSelectedItems(value);
      },
    },
    bankGroupCodesUsingInitials() {
      const collateralBankObjs = this.items(this.collateralBankCrudKey) || [];
      const updatedObj = collateralBankObjs.map((c) => c.bankIntials).filter(Boolean);
      return updatedObj;
    },
  },
  methods: {
    ...mapActions(['flashInfo', 'flashSuccess']),
    ...mapActions('shared/bank', [
      'loadBanks',
      'loadBankWithCollateralList',
      'upsertBank',
    ]),
    ...mapMutations('base/crud', [
      'setCriteria',
    ]),
    ...mapMutations('shared/bank', [
      'setSelectedItems',
    ]),
    ...mapActions('common/renumberLines', [
      'cmnRenumberLines',
    ]),
    ...mapActions('glDaily/glCollateralBankTransaction', [
      'loadGLCollateralBankTrans',
    ]),
    handleLoadCRUDList() {
      const { baseCrudKey } = this;
      const criteria = this.criteria(baseCrudKey);
      this.loadBankWithCollateralList({
        criteria,
        baseCrudKey,
      }).then(() => {
        this.setDefaultSelectedItems();
      });
    },
    isNewItem() {
      return this.index(this.baseCrudKey) === -1;
    },
    async upsrtBank(item) {
      const { baseCrudKey } = this;
      const updatedItem = { ...item };
      if (!this.isNewItem()) {
        if (!window.confirm('This will affect to all associated Transactions, are you sure!')) {
          return false;
        }
      }
      if (updatedItem.bankCollateralRecord) {
        delete updatedItem.bankCollateralRecord;
      }
      await this.upsertBank({ item: updatedItem, baseCrudKey });
      this.setDefaultSelectedItems();
      return true;
    },
    setDefaultSelectedItems() {
      this.$nextTick(() => {
        this.selectedItems = [];
      });
    },
    emitSelectedItem(item) {
      this.selectedItems = item;
    },
    newItemDialog() {
      let newItem = {};
      newItem = {
        bankCollateralRecord: {
          details: [],
        },
      };
      return newItem;
    },
    async renumberLines() {
      if (window.confirm('Are you sure you want to renumber lines?')) {
        if (this.selectedItems[0].line !== null) {
          await this.cmnRenumberLines({
            criteria: {
              line: this.selectedItems[0].line,
            },
            baseCrudKey: this.baseCrudKey,
          });
          this.handleLoadCRUDList();
          this.flashSuccess('All the line numbers are now successfully renumbered!');
        } else {
          this.setDefaultSelectedItems();
          this.flashInfo('The selected transaction does not contain line number!');
        }
      }
      this.setDefaultSelectedItems();
    },
    loadCollateralList() {
      this.loadGLCollateralBankTrans({ criteria: {}, baseCrudKey: this.collateralBankCrudKey });
    },
  },
};

</script>
