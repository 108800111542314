<template>
<v-container>
  <h3>Bank Collateral Details</h3>
  <v-row
  class="d-flex align-end"
  >
      <v-col col="3">
        <ClarionDateControl
        :isIsoDate="true"
        label="As Of"
        v-model="collateralInfo.asOfDate"
        />
      </v-col>
    <v-col cols="3">
      <v-text-field
        label="Bank Initials"
        v-model="collateralInfo.bankIntials"
      ></v-text-field>
    </v-col>
    <v-col cols="3">
      <v-text-field
        label="Bank Name"
        v-model="collateralInfo.bankName"
      ></v-text-field>
    </v-col>
    <v-col cols="3">
      <v-text-field
        label="Cash Balance"
        v-model="formatCashBalance"
      ></v-text-field>
    </v-col>
  </v-row>
   <v-data-table
    dense
    :headers="headers"
    :items-per-page="-1"
    hide-default-footer
    :items="collateralInfo.details || []"
    class="elevation-1 glBankCollateralDetails"
  >
    <template  v-slot:[`item.asOfDate`]="{ item }">
      <v-col col="3">
        <ClarionDateControl
        :isIsoDate="true"
        label="As Of"
        v-model="item.asOfDate"
        />
      </v-col>
    </template>
     <template v-slot:[`item.description`]="{ item }">
      <v-text-field
        dense
        single-line
        label="Description"
        v-model="item.description"
      ></v-text-field>
     </template>
     <template v-slot:[`item.amount`]="{ item }">
      <v-text-field
        dense
        single-line
        label="Amount"
        v-model="item.amount"
      ></v-text-field>
     </template>
     <template v-slot:[`item.fdic`]="{ item }">
      <v-text-field
        dense
        single-line
        label="FDIC"
        v-model="item.fdic"
      ></v-text-field>
    </template>

  </v-data-table>
  </v-container>
</template>

<script>
import {
  mdiPlus,
  mdiDelete,
} from '@mdi/js';
import ClarionDateControl from '../../common/ClarionDateControl.vue';
import {
  amountFormat,
} from '../../../util/shared/vue-global';

export default {
  name: 'glBankCollateralDetails',
  created() {
  },
  components: {
    ClarionDateControl,
  },
  props: {
    baseCrudKey: {
      type: String,
      default: 'glBankCollateralDetails-dialog',
    },
    collaterlInfoDetails: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    icons: {
      mdiPlus,
      mdiDelete,
    },
    defaultHeaders: [
      { text: 'As Of', value: 'asOfDate' },
      { text: 'Description', value: 'description' },
      { text: 'Amount', value: 'amount' },
      { text: 'FDIC', value: 'fdic' },
    ],
  }),
  computed: {
    headers() {
      return this.defaultHeaders;
    },
    collateralInfo: {
      get() {
        if (this.value && Object.keys(this.value).length > 0) {
          return (this.value);
        }
        return {
          details: [],
        };
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    formatCashBalance() {
      return amountFormat(this.collateralInfo.cashBalance);
    },
  },
  methods: {
    amountFormat,
  },
};
</script>

<style lang="sass">
  .eft-rec-amount input
    text-align: right
</style>
