var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h3',[_vm._v("Bank Collateral Details")]),_c('v-row',{staticClass:"d-flex align-end"},[_c('v-col',{attrs:{"col":"3"}},[_c('ClarionDateControl',{attrs:{"isIsoDate":true,"label":"As Of"},model:{value:(_vm.collateralInfo.asOfDate),callback:function ($$v) {_vm.$set(_vm.collateralInfo, "asOfDate", $$v)},expression:"collateralInfo.asOfDate"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"label":"Bank Initials"},model:{value:(_vm.collateralInfo.bankIntials),callback:function ($$v) {_vm.$set(_vm.collateralInfo, "bankIntials", $$v)},expression:"collateralInfo.bankIntials"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"label":"Bank Name"},model:{value:(_vm.collateralInfo.bankName),callback:function ($$v) {_vm.$set(_vm.collateralInfo, "bankName", $$v)},expression:"collateralInfo.bankName"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"label":"Cash Balance"},model:{value:(_vm.formatCashBalance),callback:function ($$v) {_vm.formatCashBalance=$$v},expression:"formatCashBalance"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1 glBankCollateralDetails",attrs:{"dense":"","headers":_vm.headers,"items-per-page":-1,"hide-default-footer":"","items":_vm.collateralInfo.details || []},scopedSlots:_vm._u([{key:"item.asOfDate",fn:function(ref){
var item = ref.item;
return [_c('v-col',{attrs:{"col":"3"}},[_c('ClarionDateControl',{attrs:{"isIsoDate":true,"label":"As Of"},model:{value:(item.asOfDate),callback:function ($$v) {_vm.$set(item, "asOfDate", $$v)},expression:"item.asOfDate"}})],1)]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"dense":"","single-line":"","label":"Description"},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}})]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"dense":"","single-line":"","label":"Amount"},model:{value:(item.amount),callback:function ($$v) {_vm.$set(item, "amount", $$v)},expression:"item.amount"}})]}},{key:"item.fdic",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"dense":"","single-line":"","label":"FDIC"},model:{value:(item.fdic),callback:function ($$v) {_vm.$set(item, "fdic", $$v)},expression:"item.fdic"}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }