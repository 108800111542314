<template>
  <BankList />
</template>

<script>

import BankList from '@/components/shared/bank/BankList.vue';

export default {
  name: 'Banks',
  components: {
    BankList,
  },
};

</script>
